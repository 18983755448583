<header>
  <!-- <p class="dev">Site en développement</p> -->
  <h1>Marie Merle</h1>
  <h2>- Secrétaire indépendante -</h2>
  <p>Je vous libère de votre travail administratif.<br>
    Vous vous concacrez exclusivement<br> à votre coeur de métier.</p>
</header>

<section id="why">
  <h1>Pourquoi une secrétaire independante ?</h1>
  <ul id="whyList">
    <li class="whyElement">
      <h2>Souple</h2>
      <ul>
        <li>Petit volume horaire</li>
        <li>Mission Ponctuel</li>
        <li>Aucun engagement sur la durée</li>
      </ul>
    </li>
    <li class="whyElement">
      <h2>Simple</h2>
      <ul>
        <li>Auncune contrainte liée a l'embauche</li>
        <li>Une lettre de mission suite a un devis</li>
        <li>Je vous facilite la vie</li>
      </ul>
    </li>
    <li class="whyElement">
      <h2>Economique</h2>
      <ul>
        <li>Aucune charges socials</li>
        <li>Facturé au temps réel</li>
        <li>Pas de nouveau poste de travail</li>
      </ul>
    </li>
  </ul>
</section>

<section id="who">
  <h1>Qui suis-je ?</h1>
  <h2>Marie MERLE</h2>
  <p>Je compte plus de 20 ans d’expérience au service des PME de tous secteurs d’activités.</p>
  <p>J’ai exercé les fonctions d’assistante de direction, responsable produits, chargée de mission au service marketing-communication<br>et aujourd’hui je suis secrétaire indépendante.</p>
  <p>Rigoureuse, organisée et créative, je mets toutes mes compétences à votre service.</p>
</section>

<section id="what">
  <h1>Prestations</h1>
  <h2  (click)="activePrestation = 'secretariatClassique'">Secretariat classique</h2>
  <p *ngIf="activePrestation === 'secretariatClassique'">
    Conception et frappe de devis, documents, livret d’accueil, compte rendus, procédures…<br>
    Suivi des échéances fournisseurs et préparation des paiements.<br>
    Préparation de pièces comptables pour transmission à votre cabinet comptable<br>
    Classement, archivage, numérisation
  </p>
  <h2  (click)="activePrestation = 'secretariatCommercial'">Secretariat commercial</h2>
  <p *ngIf="activePrestation === 'secretariatCommercial'">
    Feuilles de calcul, tableaux de bords, PowerPoint, publipostage<br>
    Facturation, suivi des règlements et relance clients<br>
    Elaboration de documents commerciaux : flyers, documentation pour salon, carte de visite, tarifs…
  </p>
  <h2  (click)="activePrestation = 'phoneSitting'">"Phone sitting"</h2>
  <p *ngIf="activePrestation === 'phoneSitting'">
    « Phone sitting » consiste à prendre temporairement soin de votre téléphone pour une période déterminée.<br>
    Pendant vos vacances, vos formations, vous me confiez votre téléphone et votre agenda que je gère pendant votre absence. Il est plus agréable d’avoir un correspond qu’un répondeur, vous ne perdez pas de contacts et je remplie votre agenda et vous remets un rapport à votre retour.
  </p>
  <h2  (click)="activePrestation = 'packOrganisation'">Pack organisation</h2>
  <p *ngIf="activePrestation === 'packOrganisation'">
    Vous êtes perdu dans vos papiers ou vous démarrez une activité, mettez les bonnes méthodes en place :<br>
    -	Physiquement : classeurs, cahiers, tableaux, bannettes, trieurs…<br>
    -	Plan d’organisation, arborescence informatique<br>
    -	Création de tableaux de bord Excel, trames de documents WORD, Echéanciers…
  </p>
  <h2 (click)="activePrestation = 'conseil'">Conseil</h2>
  <p *ngIf="activePrestation === 'conseil'">
    Prendre du temps pour vous aider dans la gestion de projet en sortant du quotidien<br>
    Organisation d’Evénements<br>
    Marketing
  </p>
</section>

<section id="price">
  <h1>Mes tarifs</h1>
  <h2>Secreteriat :</h2>
  <table>
    <tr>
      <th>Heures par mois</th>
      <th>&lt; 5H</th>
      <th>&lt; 16H</th>
      <th>&lt; 33H</th>
      <th>&gt; 35H</th>
    </tr>
    <tr id="priceLine">
      <td>Taux horaire*</td>
      <td>31€</td>
      <td>29€</td>
      <td>27€</td>
      <td>25€</td>
    </tr>
  </table>
  <p class="asterix">*Minimum de facturation une heure - Frais de déplacement inclu dans un rayon de 20km depuis Quimper</p>
  <h2>Autres prestations :</h2>
  <p>Après analyse du besoin, un devis gratuit est établi.</p>
</section>

<section id="contact">
  <h1>Contact</h1>
  <h2>06.64.51.35.31</h2>
  <h2>marie@merle.bzh</h2>
  <h2>Quimper</h2>
</section>
